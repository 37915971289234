import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    ThemeProvider,
    createTheme,
} from '@material-ui/core';

import './firebase';

// import i18n (needs to be bundled ;))
import './i18n/index';


const theme = createTheme({
    palette: {
        primary: {
            main: '#FF0000',
            light: '#FF6464',
            dark: '#9C0000',
        },
        secondary: {
            main: '#1240AB',
            light: '#5877C0',
            dark: '#082568',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Montserrat',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },

});

ReactDOM.render(
    <React.Fragment>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


