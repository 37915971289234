import React from 'react';
import {
    Grid,
    Typography,
    Box,
    Container,
    Link,
    Avatar,
    Dialog,
    useScrollTrigger,
    Zoom,
    Fab,
} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';

import Logo from './logo'

import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import Form from "./form";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    footer: {
        fontSize: 16,
        padding: theme.spacing(6, 0),
        margin: theme.spacing(6, 'auto'),
    },
    separator: {
        height: '2px',
        opacity: '0.2',
        backgroundImage: `linear-gradient(to left, 
                            ${theme.palette.primary.main}E6, 
                            ${theme.palette.primary.light}CC, 
                            ${theme.palette.secondary.light}CC, 
                            ${theme.palette.secondary.main}E6)`,
    },
    spanFooter: {
        padding: theme.spacing(0, 0.8),
    },
    divider: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 0.8),
    },
    avatar: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    scrollTop: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    }
}));



export default function Footer(props) {

    const classes = useStyles();

    const [ t, i18n ] = useTranslation()

    const [openForm, setOpenForm] = React.useState(false);

    const formClick = (event) => {
        event.preventDefault();
        setOpenForm(true);
    };

    const linkNone = (event) => {
        event.preventDefault();
    };

    function ScrollTop(props) {
        const {children, window} = props;
        const classes = useStyles();
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger( {
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 1000,
        } );
        const handleClick = (event) => {
            const anchor = (event.target.ownerDocument || document).querySelector( '#top' );
            if (anchor) {
                anchor.scrollIntoView( {behavior: 'smooth', block: 'center'} );
            }
        };
        return (
            <Zoom in={trigger}>
                <div onClick={handleClick} role="presentation" className={classes.scrollTop}>
                    {children}
                </div>
            </Zoom>
        );
    }

    return (
        <footer>
            <footer className={classes.separator}/>
            <Container maxWidth="lg" component="footer" className={classes.footer}>
                <Grid container spacing={5} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="h6" color="textPrimary" gutterBottom>
                                    <Logo/>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">

                                <Link href="#" className={classes.link} underline="none" variant="inherit" color="textPrimary" onClick={formClick}>
                                    {t('footer.menu.contact')}
                                </Link>
                                <Dialog open={openForm} onClose={() => setOpenForm(false)} aria-labelledby="contact-form">
                                    <Form/>
                                </Dialog>
                                <div className={classes.divider}/>
                                <Link href="/legal/privacy" className={classes.link} underline="none" variant="inherit" color="textPrimary" >
                                    {t('footer.menu.privacy')}

                                </Link>
                                <div className={classes.divider}/>
                                <Link href="/legal/terms" className={classes.link} underline="none" variant="inherit" color="textPrimary">
                                    {t('footer.menu.terms')}
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    MyAppointment® {new Date().getFullYear()}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    <Link className={classes.link} underline="none" variant="inherit" color="textPrimary" href={`mailto:${t('footer.email')}`}>
                                        {t('footer.email')}
                                    </Link>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Button color="secondary" onClick={() => i18n.changeLanguage("es")}>ES</Button>
                                <Button color="secondary" onClick={() => i18n.changeLanguage("en")}>EN</Button>
                                <Button color="secondary" onClick={() => i18n.changeLanguage("br")}>BR</Button>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    {t('footer.follow')}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Link href="#" onClick={linkNone}>
                                    <Avatar className={classes.avatar}>
                                        <TwitterIcon/>
                                    </Avatar>
                                </Link>
                                <span className={classes.spanFooter}/>
                                <Link href="#" onClick={linkNone}>
                                    <Avatar className={classes.avatar}>
                                        <LinkedInIcon/>
                                    </Avatar>
                                </Link>
                                <span className={classes.spanFooter}/>
                                <Link href="#" onClick={linkNone}>
                                    <Avatar className={classes.avatar}>
                                        <FacebookIcon/>
                                    </Avatar>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <ScrollTop {...props}>
                <Fab color="secondary" size="small" aria-label="scroll back to top" className={classes.avatar}>
                    <HomeIcon/>
                </Fab>
            </ScrollTop>
        </footer>
    );
}
