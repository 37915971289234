import React from 'react';
import {makeStyles, Typography, Container, Grid, Card, CardContent,} from '@material-ui/core';
import { ReactComponent as widgetImg } from '../../assets/icons/widgetIcon.svg';
import { ReactComponent as messageImg } from '../../assets/icons/messageIcon.svg';
import { ReactComponent as dashboardImg } from '../../assets/icons/dashboardIcon.svg';
import CardHeader from "@material-ui/core/CardHeader";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(12, 0),
    },
    cards: {
        padding: theme.spacing(3, 0, 2),
        margin: theme.spacing(1),
        borderRadius: 22.5,
    },
    cardHeader:{
        textAlign: 'center',
        '& span':{
            fontSize: '1.2rem',
        },
    },
    animatedBtn: {
        transition: 'all .25s ease-in-out',
        boxShadow: '0 3px 20px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            transform: 'scale(1) translateY(-10px) translateZ(20px)',
            boxShadow: '0 1rem 3rem rgba(31,45,61,.125)',
        },
    },
    cardsIcons:{
        width: '100%',
        height: '150px',
        '& .st0': {
            fill: theme.palette.action.disabledBackground,
        },
        '& .st1': {
            fill: theme.palette.secondary.main,
        },
        '& .st2': {
            fill: theme.palette.secondary.light,
        },
        '& .st3': {
            fill: theme.palette.primary.main,
        },
        '& .st4': {
            fill: theme.palette.common.black,
        },
        '& .st5': {
            fill: theme.palette.common.white,
        },
        '& .st6': {
            fill: theme.palette.primary.light,
        },
    },
    [theme.breakpoints.down('md')]: {
        CenterGrid: {
            margin: theme.spacing(1),
            textAlign: 'center',
        },
    }
}));


export default function FeaturesSection() {

    const classes = useStyles();
    const [ t ] = useTranslation()

    const cards = [
        {
            title: `${t('home.feactures.cards.one.title')}`,
            excerpt: `${t('home.feactures.cards.one.excerpt')}`,
            image: widgetImg,
        },

        {
            title: `${t('home.feactures.cards.two.title')}`,
            excerpt: `${t('home.feactures.cards.two.excerpt')}`,
            image: messageImg,
        },

        {
            title: `${t('home.feactures.cards.three.title')}`,
            excerpt: `${t('home.feactures.cards.three.excerpt')}`,
            image: dashboardImg,
        },
    ];



    return (
        <section id="features" className={classes.background}>
            <Container maxWidth="md" >
                <Grid container >
                    <Grid item xs={12} md={9} className={`${classes.CenterGrid}`}>
                        <Typography component="h2" variant="h3" color="textPrimary" gutterBottom>
                            {t('home.feactures.title')}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" paragraph>
                            {t('home.feactures.text_1')}
                            <br/>
                            {t('home.feactures.text_2')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4} alignItems="flex-end">
                    {cards.map(cards => (
                        <Grid item key={cards.title} xs={12} sm={4} md={4}>
                            <Card className={`${classes.cards} ${classes.animatedBtn}`}>
                                <Typography align="center">
                                    <cards.image alt={cards.title} className={classes.cardsIcons}/>
                                </Typography>
                                <CardHeader
                                    title={cards.title}
                                    subheader={cards.subheader}
                                    className={classes.cardHeader}/>
                                <CardContent>
                                    <Typography align="center" variant="body2" component="p">
                                        {cards.excerpt}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </section>
    );
}