import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
// Languages
import ES from './es.json'
import EN from './en.json'
import BR from './br.json'

// Translates
const resources = {
    en: { translation: EN },
    es: { translation: ES },
    br: { translation: BR },
};
export const defaultLanguages = "en";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: defaultLanguages,
        interpolation: {
            escapeValue: false
        },
    });

export const lang = [
    { value: "en", name: "English" },
    { value: "es", name: "Español" },
    { value: "br", name: "Portuguese" },
]

export default i18n;