import React from "react";
import {BrowserRouter} from "react-router-dom"
import Header from './assets/common/header';
import Footer from './assets/common/footer';
import Routes from './navigation/routes'
import {CssBaseline} from "@material-ui/core";

export default function App() {

    return (
        <React.Fragment>
            <CssBaseline />
            <BrowserRouter>
                <Header/>
                <Routes/>
                <Footer/>
            </BrowserRouter>
        </React.Fragment>
    );
}



