import React from 'react';
import Header from './header';
import FeaturesSection from './features'
import BenefitsSection from './benefits'
import AcquireSection from './acquire'

export default function Home() {

    return (
        <>
            <Header />
            <FeaturesSection />
            <BenefitsSection />
            <AcquireSection />
        </>
    );
}