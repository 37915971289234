import React from 'react';
import {
    Container,
    CssBaseline,
    makeStyles,
} from '@material-ui/core';

//  Import components here...


export default function _() {

    const useStyles = makeStyles((theme) => ({
            ptb100: {
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(12, 0),
            },

            [theme.breakpoints.down('md')]: {
//  Breakpoint Style
                ptb100: {
                    padding: theme.spacing(2, 0)
                },
            },
        }))
    ;
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline/>
            {/*    start the code here...   */}
            <section className={classes.ptb100}>
                <Container>
                    <h1>Privacy Policy</h1>
                </Container>
            </section>

        </React.Fragment>
    );
}


