import React, { useEffect, useState } from "react";
import { auth } from "../../firebase"

export const AuthContext = React.createContext( { user: null });
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
    useEffect(() => {
        auth.onAuthStateChanged((user) => {

                setCurrentUser( user );
                setPending( false )


        });
    }, []);
    if(pending){
        return <>Please wait...</>
    }
    return (
        <AuthContext.Provider value={{currentUser}} >
            {children}
        </AuthContext.Provider>
    );
};