import React from 'react';
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';

import { ReactComponent as IphoneImg } from '../../assets/images/confirmed.svg'
import { ReactComponent as Shape } from '../../assets/shapes/shape-image-bg.svg'
import {faCheck, faChartLine,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    ptb100: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(12, 0),
    },
    bgShape: {
        position: 'absolute',
        zIndex: '1',
        top: 0,
        left: '-50%',
        right: 0,
        width: '100%',
        display: 'block',
        fill: theme.palette.primary.main,
    },
    MuiGridItem: {
        position: 'relative',
    },
    spacing: {
        marginBottom: theme.spacing(2)
    },
    icon: {
        color: theme.palette.primary.main,
    },
    center: {
        textAlign: 'left',
    },
    cardsIcons:{
        zIndex: '2',
        '& .st0': {
            fill: theme.palette.action.disabledBackground,
        },
        '& .st1': {
            fill: theme.palette.secondary.main,
        },
        '& .st2': {
            fill: theme.palette.secondary.light,
        },
        '& .st3': {
            fill: theme.palette.primary.main,
        },
        '& .st4': {
            fill: theme.palette.common.black,
        },
        '& .st5': {
            fill: theme.palette.common.white,
        },
        '& .st6': {
            fill: theme.palette.primary.light,
        },
    },

    [theme.breakpoints.down('md')]: {
        ptb100: {
            padding: theme.spacing(2, 0)
        },
        bgShape: {
            display: 'none',
        },
        center: {
            textAlign: 'center',
        },
        MuiGridItem: {
            paddingBottom: theme.spacing(4)
        }

    },
}));

export default function BenefitsSection() {

    const classes = useStyles();
    const [ t] = useTranslation()

    const cards = [
        {
            title: `${t('home.benefits.cards.one.title')}`,
            excerpt: `${t('home.benefits.cards.one.excerpt')}`,
            image: faChartLine,
        },

        {
            title: `${t('home.benefits.cards.two.title')}`,
            excerpt: `${t('home.benefits.cards.two.excerpt')}`,
            image: faCheck,
        },
    ];

    return (
        <Box clone display={{xs: 'none', sm: 'none', md: 'block'}}>
            <section id="benefits" className={classes.ptb100}>
                <Container maxWidth="md" className={classes.center}>
                    <Grid container>
                        <Grid item xs={12} sm={5}>
                            <Grid container alignItems="center" className={classes.MuiGridItem}>
                                <IphoneImg alt='iphone' height="100%" className={classes.cardsIcons}/>
                                <div className={classes.bgShape}>
                                    <Shape/>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Typography component="h2" variant="h3">
                                        {t('home.benefits.title')}
                                    </Typography>
                                    <div className={classes.spacing}/>
                                    <Typography component="p" variant="subtitle1">
                                        {t('home.benefits.text_1')}
                                    </Typography>
                                    <div className={classes.spacing}/>
                                    <Typography component="p" variant="subtitle1">
                                        {t('home.benefits.text_2')}
                                    </Typography>
                                    <div className={classes.spacing}/>
                                    <Grid container spacing={1}>
                                        {cards.map( cards => (
                                            <Grid item key={cards.title} xs={12} md={6} lg={6}>
                                                <Typography gutterBottom variant="h5" component="p">
                                                    <FontAwesomeIcon icon={cards.image} size="2x"
                                                                     className={classes.icon}/>
                                                </Typography>
                                                <div className={classes.spacing}/>
                                                <Typography component="h3" variant="h6">
                                                    {cards.title}
                                                </Typography>
                                                <div className={classes.spacing}/>
                                                <Typography component="p" variant="subtitle1">
                                                    {cards.excerpt}
                                                </Typography>
                                            </Grid>
                                        ) )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </Box>
    );
}


