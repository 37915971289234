import React from 'react';
import {
    makeStyles,
    Container,
    Typography,
    Grid,
    Button,
    Box,
} from '@material-ui/core';

import ImageBackground from '../../assets/bg/header-bg-1.jpg';
import Shape from '../../assets/shapes/hero-shape.svg'
import IphoneImage from '../../assets/images/laptop-tablet.png'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    ImageBack: {
        background: `url(${ImageBackground}) no-repeat center center / cover`,
    },
    HeroEqualHeight: {
        position: 'relative',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        webkitBoxOrient: 'vertical',
        webkitBoxDirection: 'normal',
        webkitBoxPack: 'center',
        flexPack: 'center',
        height: '100%',
        width: '100%',
    },
    GradientOverlay: {
        width: '100%',
        '&::before': {
            position: 'absolute',
            content: '""',
            backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark}BF, ${theme.palette.primary.main}BF, ${theme.palette.primary.light}BF)`,
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
        }
    },
    Ptb12: {
        padding: theme.spacing(12, 0),
    },
    VideoAppWrap: {
        boxSizing: 'border-box',
        minHeight: '1px',
        left: 'auto',
        zIndex: '5',
        right: '-5%',
        maxWidth: '900px',
        flex: '0 0 38%',
        top: '90px',
    },
    IphoneMask: {
        zIndex: '10',
        objectFit: 'cover',
        width: '100%',
        overflow: 'hidden',
    },
    MaskImg: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
        zIndex: '10',
        transition: 'all 400ms ease-in 0s',
    },
    ImgFluid: {
        maxWidth: '100%',
        height: 'auto',
    },
    ShapeBottom: {
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '-16px',
        zIndex: '1',
        width: '100%',
        margin: theme.spacing(0, 'auto'),
    },
    OverflowHidden: {
        overflow: 'hidden!important',
    },
    heroButtons: {
        margin: theme.spacing(4, 0),
    },
    heroGrid: {
        marginBottom: theme.spacing(2),
    },
    WhiteBtn: {
        padding: '12px 30px',
        fontWeight: '700',
    },
    solidWhiteBtn: {
        background: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        color: theme.palette.secondary.main,
        borderRadius: '30px',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
        }
    },
    outlineWhiteBtn: {
        borderRadius: '30px',
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.main,
        }
    },
    animatedBtn: {
        transition: 'all .25s ease-in-out',
        '&:hover': {
            transform: 'scale(1) translateY(-4px) translateZ(20px)',
        },
    },
    heroContent: {
        padding: theme.spacing(8, 0, 4),
        position: 'relative',
    },
    textWhite: {
        color: theme.palette.common.white,
    },
    textLight: {
        color: theme.palette.common.white,
    },
    [theme.breakpoints.down('md')]: {
        VideoAppWrap: {
            position: 'relative',
            left: '5%',
            right: '5%',
            top: '0px',
        },
        Ptb12: {
            padding: theme.spacing(2, 0),
        },
        heroContent: {
            padding: theme.spacing(4, 0, 2),
        },
        ShapeBottom: {
            bottom: '-6px',
        },
        IphoneMask: {
            marginBottom: '-45px',
        },
        heroGrid: {
            margin: theme.spacing(1),
            textAlign: 'center',
        },
    }
}));

export default function Header() {

    const classes = useStyles();

    const [ t ] = useTranslation()

    return (
        <section id="header" className={`${classes.HeroEqualHeight} ${classes.Ptb12} ${classes.GradientOverlay} ${classes.ImageBack}`} position="relative">
            <Container maxWidth="lg" className={classes.heroContent}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Typography component="h1" variant="h3" className={`${classes.textWhite} ${classes.heroGrid}`}>
                            {t('home.header.title')}
                        </Typography>
                        <Typography component="p" variant="h6" className={`${classes.textLight} ${classes.heroGrid}`}>
                            {t('home.header.text')}
                        </Typography>
                        <div className={classes.heroButtons}>
                            <Button variant="outlined" selected
                                    className={`${classes.WhiteBtn} ${classes.outlineWhiteBtn} ${classes.animatedBtn}`}
                                    href="https://app.myappointment.dev/signup" target="_blank">
                                {t('home.header.button')}
                            </Button>
                        </div>
                    </Grid>
                    <Box clone display={{xs: 'none', sm: 'none', md: 'block'}}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Box flexWrap="wrap" alignItems="center" justifyContent="flex-end"
                                 className={classes.VideoAppWrap} position="absolute">
                                <Box clone display="flex" flexDirection="row" justifyContent="center"
                                     className={classes.IphoneMask} position="relative">
                                    <img src={IphoneImage} alt="app"
                                         className={`${classes.MaskImg} ${classes.ImgFluid}`}/>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
            <div className={`${classes.ShapeBottom} ${classes.OverflowHidden}`}>
                <img src={Shape} alt="shape"/>
            </div>
        </section>
    );
}


