import React from 'react';
import {
    Link,
    Box,
} from "@material-ui/core";
import {ReactComponent as LogoIcon} from '../logo.svg'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: '100%',
        paddingTop: theme.spacing(1),
        '& .cls-1': {
            fill: theme.palette.primary.main,
        },
        '& .cls-2': {
            fill: theme.palette.secondary.main,
        },
        '& .cls-7': {
            fill: theme.palette.common.white,
        },
        '& .cls-8': {
            stroke: theme.palette.common.white,
        },
        '& .cls-9': {
            strokeWidth: theme.spacing(1.5),
        },
        '& .cls-10': {
            strokeWidth: theme.spacing(3),
        },
    },
}));
export default function Logo() {

    const classes = useStyles();

    return (
        <Box display="flex" flexWrap="nowrap">
            <Link href="/" underline="none" alt="logotipo">
                <LogoIcon className={classes.logo}/>
            </Link>
        </Box>
    );
}