import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from '../pages/home/index';
import PrivacyPolicy from '../pages/legal/PrivacyPolicy';
import TermsAndConditions from '../pages/legal/TermsAndConditions';
import * as path from './paths';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path={path.HomePath} element={<Home />} />
            <Route path={path.PrivacyPath} element={<PrivacyPolicy />} />
            <Route path={path.TermPath} element={<TermsAndConditions />} />
            {/* Asumiendo que quieres proteger la ruta HomePath con PrivateRoute */}
            <Route
                path={path.HomePath}
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}