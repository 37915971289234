import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "./Auth/AuthContext";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = useContext(AuthContext);

    return (
        <Routes>
            <Route
                {...rest}
                element={
                    currentUser
                        ? <RouteComponent />
                        : <Navigate to="/" replace />
                }
            />
        </Routes>
    );
};

export default PrivateRoute