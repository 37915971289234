import React from 'react';
import { useTranslation } from 'react-i18next'
import clsx from 'clsx';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Dialog,
    Drawer,
    List,
    ListItem, useScrollTrigger,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles } from '@material-ui/core/styles';
import Logo from './logo'
import Form from "./form";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        display: 'inline',
        margin: theme.spacing(1, 1.5),
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    divider: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(2, 2),
        marginLeft: theme.spacing(2),
    },
    outlineWhiteBtn: {
        borderRadius: '30px',
        color: theme.palette.common.white,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(0, 0.5),
        transition: 'all .25s ease-in-out',
        '&:hover': {
            color: theme.palette.common.white,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            transform: 'scale(1) translateY(-4px) translateZ(20px)',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },

    },

    [theme.breakpoints.down('sm')]: {
        //  Breakpoint Style
        headerDisplay: {
            display: 'grid',
        },
    },

    [theme.breakpoints.down('md')]: {
        //  Breakpoint Style
        link: {
            display: 'list-item',
        },
        toolbarTitle: {
            maxWidth: '83%'
            },
    },
}));


export default function Header(props) {

    const classes = useStyles();

    const [ t ] = useTranslation()

    const [open, setOpen] = React.useState( );

    const toggleDrawer = (open) => (event) => {
        event.preventDefault();
        setOpen(open);
    };

    function MenuItems(props) {

        const classes = useStyles();

        const [openForm, setOpenForm] = React.useState(false);

        const formClick = (event) => {
            event.preventDefault();
            setOpenForm(true);
        };

        const {window} = props;
        const trigger = useScrollTrigger( {
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 1000,
        } );
        const pricesClick = (event) => {
            const anchor = (event.target.ownerDocument || document).querySelector( '#prices' );
            if (anchor) {
                anchor.scrollIntoView( {behavior: 'smooth', block: 'center'} );
            }
        };
        const featuresClick = (event) => {
            const anchor = (event.target.ownerDocument || document).querySelector( '#features' );
            if (anchor) {
                anchor.scrollIntoView( {behavior: 'smooth', block: 'center'} );
            }
        };

        return (
            <List className={classes.fullList} onClick={toggleDrawer( false )} onKeyDown={toggleDrawer( false )}>
                <ListItem variant="button" underline="none" href="/" className={classes.link}>
                    {t('header-bar.menu.home')}
                </ListItem>
                <ListItem in={trigger} variant="button" underline="none" className={classes.link} onClick={featuresClick}>
                    {t('header-bar.menu.characteristics')}
                </ListItem>
                <ListItem in={trigger} variant="button" underline="none" className={classes.link} onClick={pricesClick}>
                    {t('header-bar.menu.prices')}
                </ListItem>
                <ListItem variant="button" underline="none" className={classes.link} onClick={formClick}>
                    {t('header-bar.menu.contact')}
                </ListItem>
                <Dialog open={openForm} onClose={() => setOpenForm( false )} aria-labelledby="contact-form">
                    <Form/>
                </Dialog>
            </List>
        );
    }

    return (
        <header>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar} id="top">
                <Toolbar className={classes.toolbar} >
                    <Typography xs={8} variant="h6" className={classes.toolbarTitle}>
                        <Logo/>
                    </Typography>
                    <Hidden mdUp>
                        <IconButton xs={4} color="inherit" onClick={toggleDrawer(true)} className={clsx( classes.menuButton, open && classes.hide )}>
                            <MenuIcon/>
                        </IconButton>
                        <Drawer open={open} onClose={toggleDrawer(false)} >
                            <MenuItems/>
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown>
                        <nav className={classes.headerDisplay}>
                            <MenuItems/>
                        </nav>
                        <Hidden smDown>
                            <div className={classes.divider}/>
                            {/*<Button href="https://app.myappointment.dev/signup" variant="outlined"*/}
                            {/*        className={classes.outlineWhiteBtn}>*/}
                            {/*    Sign Up*/}
                            {/*</Button>*/}
                            <Button href="https://app.myappointment.dev" variant="outlined"
                                    className={classes.outlineWhiteBtn}>
                                {t('button.login')}
                            </Button>
                        </Hidden>
                    </Hidden>

            </Toolbar>
            </AppBar>
        </header>
    );
}
