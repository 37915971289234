import React from 'react';
import {
    Button,
    Container, Grid,
    makeStyles, Typography,
} from '@material-ui/core';

import ImageBackground from '../../assets/bg/hero-bg-1.jpg';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    ptb100: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(6, 0),
        position: 'relative',
    },
    GradientOverlay: {
        width: '100%',
        '&::before': {
            position: 'absolute',
            content: '""',
            backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark}BF, ${theme.palette.primary.main}BF, ${theme.palette.primary.light}BF)`,
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
        }
    },
    ImageBack: {
        background: `url(${ImageBackground}) no-repeat center center / cover`,
    },
    BtnRadius: {
        borderRadius: '30px',
    },
    WhiteBtn: {
        padding: '12px 30px',
        fontWeight: '700',
    },
    solidWhiteBtn: {
        background: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        color: theme.palette.secondary.main,
        borderRadius: '30px',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
        }
    },
    outlineWhiteBtn: {
        borderRadius: '30px',
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.main,
        }
    },
    animatedBtn: {
        transition: 'all .25s ease-in-out',
        '&:hover': {
            transform: 'scale(1) translateY(-4px) translateZ(20px)',
        },
    },
    text: {
        color: theme.palette.common.white,
        marginBottom: theme.spacing(2),
    },
    content: {
        padding: theme.spacing(8, 0, 4),
        position: 'relative',
    },

    [theme.breakpoints.down('md')]: {
//  Breakpoint Style
        ptb100: {
            padding: theme.spacing(2, 0)
        },
    },
}));

export default function AcquireSection() {

    const classes = useStyles();
    const [ t ] = useTranslation()

    return (
        <section id="acquire" className={`${classes.ptb100} ${classes.GradientOverlay} ${classes.ImageBack}`}>
            <Container maxWidth="sm" className={classes.content}>
                <Typography component="h2" variant="h4" align="center" className={classes.text}>
                    {t('home.acquire.title_1')}
                    <br/>
                    {t('home.acquire.title_2')}
                </Typography>
                <div className={classes.heroButtons}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button variant="outlined" selected
                                    className={`${classes.WhiteBtn} ${classes.outlineWhiteBtn} ${classes.animatedBtn}`}
                                    href="https://app.myappointment.dev/signup" target="_blank">
                                {t('home.acquire.button')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>
    );
}


