import { initializeApp } from "firebase/app";
import {
    getAuth,
} from "firebase/auth";
import {
    getFirestore,
} from "firebase/firestore";
import {
    getAnalytics
} from "firebase/analytics";

// Your web bookform's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCrGOlT7ET4qEHutTwWG_D7O9nFormWP0w",
    authDomain: "myappointment-dev.firebaseapp.com",
    projectId: "myappointment-dev",
    storageBucket: "myappointment-dev.appspot.com",
    messagingSenderId: "656230455577",
    appId: "1:656230455577:web:75777587523b6a6b574d88",
    measurementId: "G-Y1266F9E3S"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the Firebase services we want to use
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
